<template>
 <div class="background-header position-relative sign-section">
       <div class="background-header position-relative ">
     <div class="sign-banner" style="background-image: url('/img/login-banner.073c9ba4.jpg')">      
            <div class="header-for-bg ">
              <div class="border-section">
              <div class="data-block">
               <h4>Building a world in which all women have access to sexual and reproductive health services</h4>
				<br>
				<p class="text-black">Women First Digital (WFD) is a social enterprise that offers comprehensive, practical, inclusive and personalized sexual and reproductive health information and services. WFD has the technological capacity to deliver data-driven and innovative solutions to promote user-centered decision making and improve the well-being of women and youth worldwide.</p>
               </div>
              </div>
            </div>
	   </div>
	   </div>
       <div class="row bg-light-yellow py-5">
          <div class="col-md-6 col-sm-12  py-4">  
               <div class="w-75 m-auto mr-0 sm-p-1 sm-w-100">
					   <div class="iq-header-title">
                           <h3 class="card-title">Our Network</h3>
                        </div>
						    <a class="text-black" href="https://howtouseabortionpill.org/" target="_blank">
                        <div class="iq-card bg-white">						
           
								   <div class="row py-3 px-3 align-items-center mt-4">
									   <div class="col-3 ">
										 <img src="../../../assets/images/login/login-icon1.png" width="80px" alt="logo for pill site" class="img-fluid">  
										   
										   </div>
									      <div class="col-6">
										   <a class="text-black" href="https://howtouseabortionpill.org/" target="_blank">HowtoUseAbortionPill.org</a>
										   
										   </div>
									      <div class="col-3">
										    <a class="text-black" href="https://howtouseabortionpill.org/" target="_blank">
										   <img src="../../../assets/images/login/next1.svg" width="50px" alt="arrow" class="img-fluid float-right md-w-50"></a>
										   </div>
									   
									   </div>							
							
                        </div>
							</a>

						 <a class="text-black" href="https://findmymethod.org/" target="_blank">								
					    <div class="iq-card bg-white">
					 		   <div class="row py-3 px-3 align-items-center mt-4">
									   <div class="col-3 ">
										 <img src="../../../assets/images/login/login-icon2.png" width="80px" alt="logo for pill site" class="img-fluid">  
										   
										   </div>
									      <div class="col-6">
										   <a class="text-black" href="https://findmymethod.org/" target="_blank">FindMyMethod.org</a>
										   
										   </div>
									      <div class="col-3">
										    <a class="text-black" href="https://findmymethod.org/" target="_blank">
										   <img src="../../../assets/images/login/next2.svg" width="50px" alt="arrow" class="img-fluid float-right md-w-50"></a>
										   </div>									   
									   </div>
							</div>
						 </a>

						      <a class="text-black" href="https://safe2choose.org/" target="_blank">
					 			    <div class="iq-card bg-white">
					 		   <div class="row py-3 px-3 align-items-center mt-4">
									   <div class="col-3 ">
										 <img src="../../../assets/images/login/login-icon3.png" width="80px" alt="logo for pill site" class="img-fluid">  
										   
										   </div>
									      <div class="col-6">
										   <a class="text-black" href="https://safe2choose.org/" target="_blank">safe2choose.org</a>
										   
										   </div>
									      <div class="col-3">
										    <a class="text-black" href="https://safe2choose.org/" target="_blank">
										   <img src="../../../assets/images/login/next3.svg" width="50px" alt="arrow" class="img-fluid float-right md-w-50"></a>
										   </div>									   
									   </div>
							</div> 
							  </a> 
                </div> 
          </div>          
          <div class="col-sm-12 col-md-6">  
                    <img src="../../../assets/images/login/sign-up-girl.svg" class="img-fluid w-100" alt="header-bg">
          </div>
       </div>
   </div>

 
   
  
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
export default {
  name: 'Home',
  
  mounted () {
    socialvue.index()
  },
  data: function () {
        return {
            // image: image
        }
    }
}

</script>
<style>
@import url("../../../assets/css/remixicon.css");
</style>
